import { EventEmitter } from "events";
import dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";

export const eventList = {
    TOKEN_CHANGE: "token",
    LOGIN_CHANGE: "login"
}

class UserStore extends EventEmitter {
    _token = null;
    _contactId = null;
    _activationId = null;
    _data = null;
    _noMoreAvailable = false;

    addChangeListener(event, callback) {        
        this.on(event, callback);
    }

    removeChangeListener(event, callback) {
        this.removeListener(event, callback);
    }

    emitChange(type) {
        this.emit(type);
    }  
    
    isContactClosed() {
        console.log('noMoreAvailable is', this._noMoreAvailable);
        return this._noMoreAvailable;
    }

    resetUserStoreData() {
        this._token = null;
        this._contactId = null;
        this._activationId = null;
        
        this._data = null;
        this._noMoreAvailable = false;
    }
}

const store = new UserStore();

dispatcher.register((action) => {    
    console.log(JSON.stringify(action));    

    switch (action.actionTypes) {
        case actionTypes.SET_LOGIN_TOKEN:
            if (action.data.token) {
               store._token = action.data.token;
            } else {
                console.log('No login token to set');
            }
            store.emitChange(eventList.TOKEN_CHANGE);
            break;
        
        case actionTypes.LOGIN:
            if (action.apiResponse.success && action.apiResponse.data) {
                console.log('Received login credentials', action.apiResponse.data);
                store._contactId = action.apiResponse.data.contact_id;
                store._activationId = action.apiResponse.data.special_activation_id;
            } else {
                console.log('Login failed', action.apiResponse);
                store._contactId = null
                store._activationId = null;
            } 
            store.emitChange(eventList.LOGIN_CHANGE);
            break; 

        // case actionTypes.GET_FREE_SLOTS:
        //     if (action.apiResponse.success && action.apiResponse.data) {
        //     } else {
        //         console.log('Get free slots failed', action.apiResponse);
        //     } 
        //     store.emitChange();
        //     break;        
            
        default:                                  
            // store.emitChange();
    }
});

export default store;